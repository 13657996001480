

























































































































html.hiding-layout-ui {
  ::-webkit-scrollbar {
    display: none;
  }
}
.layout-container {
  height: 100%;
}
.layout-editor-header {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 999;
  width: 170px;
  margin-left: -85px;
}
